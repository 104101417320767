<template>
  <header v-if="currentRouteName !='wait'" class="w-full h-12 flex justify-start pl-4 ">
    <ul
      class="flex justify-around w-full max-w-6xl m-auto items-center font-bold text-sm sm:text-base uppercase"
    >
      <li>
        <router-link :to="homelink"
          ><i class="fa-solid fa-house"></i>
          <span class="hidden sm:inline-block">Accueil</span></router-link
        >
      </li>

      <li>
        <router-link to="/contact"
          ><i class="fa-regular fa-envelope hidden sm:inline-block mr-1"></i
          >Contact</router-link
        >
      </li>
      <li>
        <a href="/produits.pdf" target="_blank"
          ><i class="fa-solid fa-list-check mr-1 hidden sm:inline-block"></i>
          <span class="hidden sm:inline-block">Produits éligibles</span
          ><span class="sm:hidden">Nos produits</span></a
        >
      </li>
      <li class="hidden sm:block">
        <a
          href="https://oxoformdocs.fra1.digitaloceanspaces.com/65eae16d7a94386c50e73a9b/reglement.pdf"
          target="_blank"
          ><i
            class="fa-solid fa-scale-balanced hidden sm:inline-block mr-1"
          ></i>
          Règlement</a
        >
      </li>
    </ul>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      homelink: "/",
    };
  },
  watch: {
    currentRouteName() {
      const id = sessionStorage.getItem("_id");
      const uid = sessionStorage.getItem("uid");
      if (
        (id && uid && uid !== null && id !== null) ||
        (this.$route.params._id && this.$route.params.secu)
      ) {
        this.homelink = `/status/${id || this.$route.params._id}/${
          uid || this.$route.params.secu
        }`;
      }
    },
  },
  mounted() {


  },
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.href = "/login";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
